import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { history } from "src/stores";
export const ModalConfirmLuckyDrawRetrieved = (props: any) => {
  const { isModalOpen, setIsModalOpen, prize, setPrize } = props;

  const redirect = async () => {
    history.push("/my-bag");
    setPrize({});
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setPrize({});
  };

  return (
    <Modal
      title={``}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      centered={true}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} />

      <div className="flex flex-col justify-start align-start min-h-[200px]">
        {/* Title */}
        <h2 className="page-subtitle">Congratulations</h2>
        {/* Confirmation */}
        <div className="mt-s">
          <p className={`content`}>
            You have received{" "}
            <span className="text-[#FE5218]">{prize?.name}</span> !
          </p>
          <img
            src={prize.image}
            alt="prize-img"
            className="w-full mx-auto object-fit mt-s max-w-[450px]"
          />
          {/* Confirm Button */}
          <div className="mt-m">
            <button
              className="bg-gradient-to-r from-[#F99433] to-[#FE5218] text-white  px-[20px] py-[10px] border-[0px] mx-auto w-full md:w-[300px] justify-center flex"
              onClick={() => redirect()}
            >
              Go to My Bag
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
