import axios from "axios";
import * as Configs from "../config";
import { errorHandling, setHeader } from "./api";
import { openSuccessNotification } from "src/components";

export class AirdropStore {
  async getAirdropProfile() {
    const url = `${Configs.API_URL}/v1/airdrop/profile`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getPrizeList() {
    const url = `${Configs.API_URL}/v1/airdrop/raffle-list`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async airdropRaffle(params: any) {
    const url = `${Configs.API_URL}/v1/airdrop/lucky-draw`;
    try {
      const result: any = await axios.post(url, params, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }
  async getMissionList(userId: string) {
    const url = `${Configs.API_URL}/v1/airdrop/mission-list${
      userId ? `?id=${userId}` : ""
    }`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async doDailyLogin() {
    const url = `${Configs.API_URL}/v1/airdrop/daily-login`;
    try {
      const result: any = await axios.post(url, null, setHeader());
      if (!result || !result.data?.success) return false;
      openSuccessNotification("You have done the task - DAILY LOGIN");
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async unlockReferral(token: string) {
    const url = `${Configs.API_URL}/v1/airdrop/unlock-referral`;
    try {
      const result: any = await axios.post(url, { token: token }, setHeader());
      if (!result || !result.data?.success) return false;
      openSuccessNotification("You have done the task - UNLOCK REFERRAL");
      return result["data"]["msg"];
    } catch (e) {
      return errorHandling(e);
    }
  }
}
export const STORE_AIRDROP = "airdropStore";
