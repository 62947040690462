import { Modal } from "antd";
import { CloseButton } from "./close-btn";
export const ModalConfirmAirdropRaffleRetrieved = (props: any) => {
  const { isModalOpen, setIsModalOpen, prize, setPrize } = props;

  const handleCancel = () => {
    setIsModalOpen(false);
    setPrize({});
    // window?.location?.reload();
  };

  let { type } = prize;

  let bgImg = "/assets/v3/gega-icon.png";
  let value = "";
  let prizeType = "";
  if (prize && type) {
    [prizeType, value] = type.split("_");

    switch (prizeType) {
      case "energy":
        bgImg = "/assets/v3/flash-icon.png";
        value += "%";
        break;

      case "multiplier":
        value += "%";
        break;

      case "fire":
        bgImg = "/assets/v3/FIRE.png";
        break;
    }
  }

  return (
    <Modal
      title={``}
      visible={isModalOpen}
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={null}
      centered={true}
      bodyStyle={{
        backgroundColor: "black",
        borderRadius: 10,
      }}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} />

      <div className="flex flex-col justify-center items-center min-h-[200px]">
        {/* Title */}
        <h5 className="font-[400] text-white text-[20px] uppercase">
          Congratulations
        </h5>
        {/* Confirmation */}
        <div className="mt-m">
          <p className={$label}>
            You have received{" "}
            <span className="text-[#FE5218]">
              {value} {prize?.name}
            </span>
            !
          </p>
          <img
            src={bgImg}
            alt="prize-img"
            className="w-lg mx-auto object-fit mt-m max-w-[450px]"
          />
          {/* Confirm Button */}
          <div className="mt-m">
            <button
              className="bg-gradient-to-r from-[#F99433] to-[#FE5218] text-white  px-[20px] py-[10px] border-[0px] mx-auto w-full md:w-[300px] justify-center flex"
              onClick={() => handleCancel()}
            >
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const $label = "text-white text-center";
