import { formatDollar } from "src/utils";

export const Prize = (props: any) => {
  const { prize } = props;
  const { type = "", probability = null, name = "", brand, price } = prize;

  return (
    <div className=" bg-[#1F232F] rounded-m overflow-hidden ">
      {/* Type */}
      <div className="w-full bg-[#252935] px-[20px] py-[15px] flex-row flex">
        <p className="small-content flex-1 uppercase">{type}</p>
        <p className="small-content">{probability}%</p>
      </div>

      <div className="mt-[15px] px-[20px]">
        <p className="small-title whitespace-nowrap w-full md:w-[180px] overflow-hidden text-ellipsis">
          {name}
        </p>
        <p className="small-content">{brand}</p>
      </div>
      <div className="px-[20px] mt-[20px]">
        <div className="w-[100%] border-[0px] border-[#2F333F] bg-[#252935] rounded-m overflow-hidden">
          <img
            src={prize.image}
            alt="prize-img"
            className="w-full mx-auto object-fit"
          />
        </div>
      </div>

      {/* Price */}
      {type && (
        <>
          <div className="px-[20px] pb-[10px] mt-[10px] flex flex-1 flex-row items-center justify-end">
            {type === "nft" && (
              <>
                <h2 className={$priceTag}>LUCKY LIST</h2>
              </>
            )}

            {type.indexOf("product") > -1 && (
              <>
                <h2 className={$priceTag}>HK${formatDollar(price)}</h2>
              </>
            )}

            {type === "credit" && (
              <>
                <img
                  src="/assets/GeDa.svg"
                  style={$priceIconStyle}
                  alt="geda"
                />

                <h2 className={`${$priceTag}`}>Credit {price}</h2>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const $priceTag = "mt-[9px]";
const $priceIconStyle = {
  height: 12,
  color: "#fff",
  marginTop: 0,
  marginRight: 10,
};
