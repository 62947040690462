import { LoadingIcon, openErrorNotification } from "../components";
import { history, useGameStore } from "src/stores";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Prize } from "src/components/prize";
import { Auth } from "src/components/auth";
import { useWindowSize } from "@uidotdev/usehooks";
import { useAccount } from "wagmi";
import { AuthStore } from "src/stores/auth";
import { ContainerV2 } from "src/components/container-v2";
import { GedaBtn } from "src/components/geda-btn";
import { RAFFLES_LIST } from "src/config/raffles";
import { createSignature } from "src/utils/crypto";
import { ModalConfirmLuckyDrawRetrieved } from "src/components/modal-confirm-lucky-draw-retrieved";

export const LuckyDrawGame = (props: any) => {
  const gameId = props?.match?.params?.id;
  const game = RAFFLES_LIST.find((item) => item.gameId === gameId);
  const videoRef = useRef<any>(null);

  const [gameData, setGameData] = useState<any>({});
  const [prizeContent, setPrizeContent] = useState<[]>([]);
  const [profile, setProfile] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [allLoaded, setAllLoaded] = useState<boolean>(false);
  const [animationOn, setAnimationOn] = useState<boolean>(true);
  const [prize, setPrize] = useState<any>({});
  const [openLuckyDrawRetrievedModal, setOpenLuckyDrawRetrievedModal] =
    useState<boolean>(false);
  const [showAnimation, setShowAnimation] = useState<boolean>(false);

  const { address } = useAccount();
  const size: any = useWindowSize();
  const { getGame, getPrizeContent, startDrawV2 } = useGameStore();
  const authStore = new AuthStore();

  const getPrizeContentCb = useCallback(
    (game) => {
      getPrizeContent(game["gameId"]).then((result) => {
        if (!result) return;
        setPrizeContent(result);
      });
    },
    [getPrizeContent]
  );

  const getProfileCb = useCallback(() => {
    authStore.getProfile().then((user: any) => {
      if (!user) return;
      setProfile(user);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!game) return history.push("/");

    // Fetch Profile
    if (!address) {
      setProfile({});
    }
    if (address) {
      setTimeout(() => {
        getProfileCb();
      }, 1000);
    }

    getGame(game["gameId"]).then((result) => {
      if (!result) return;
      setGameData(result);
    });

    getPrizeContentCb(game);
  }, [game, getGame, getPrizeContentCb, address, getProfileCb]);

  useEffect(() => {
    if (!gameData) return;
    if (prizeContent.length === 0) return;
    if (!profile) return;

    setAllLoaded(true);
  }, [gameData, prizeContent, profile]);

  const openBox = async () => {
    try {
      if (!gameData || !gameData?._id)
        return openErrorNotification("Please try again later");

      setLoading(true);
      const prize = await startDrawV2({
        id: gameData["_id"],
        signature: createSignature(`${gameData["_id"]}+${address}`),
      });
      setLoading(false);
      if (!prize) {
        return openErrorNotification("Please try again later");
      }
      setPrize(prize);

      if (animationOn) {
        setShowAnimation(true);
      }

      if (!animationOn) {
        setOpenLuckyDrawRetrievedModal(true);
      }
    } catch (e) {}
  };

  const handleVideoEnded = () => {
    setShowAnimation(false);
    setOpenLuckyDrawRetrievedModal(true);
    videoRef.current.currentTime = 0;
    videoRef.current.pause();
  };

  const videoRank = useMemo(() => {
    if (!prize || !prize?.probability) return null;
    if (prize?.probability <= 1) return "Legend";
    if (prize?.probability <= 10) return "Epic";
    if (prize?.probability <= 30) return "Rare";
    return "Common";
  }, [prize]);

  if (!game) return null;

  return (
    <Auth>
      <ContainerV2>
        {loading && (
          <div className="w-screen h-screen flex flex-1 justify-center items-center z-[100] bg-[#00000099] fixed top-0 left-0">
            <LoadingIcon />
          </div>
        )}
        {!allLoaded && (
          <div className="w-full pt-xl flex flex-1 justify-center items-center relative z-[15]">
            <LoadingIcon />
          </div>
        )}

        {/* Main Content */}
        {allLoaded && (
          <>
            <div className="mt-[20px] w-full relative">
              {/* Image to center */}
              <div
                className="background-video-container h-0 relative w-full"
                style={{
                  paddingTop: size.width > 1080 ? "52.72%" : "194.93%",
                }}
              >
                {/* Bg Mask */}
                <div
                  className="absolute top-0 left-0 w-full h-full"
                  style={{
                    backgroundImage: `url('/assets/v3/${
                      size.width > 1080 ? "raffle" : "mobile-banner-v2"
                    }.png')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>

                {/* Video */}
                <video
                  autoPlay
                  muted={true}
                  playsInline
                  loop
                  className="absolute top-0 left-0 w-full h-full object-cover z-[1]"
                  style={{
                    maskImage: `url('/assets/v3/${
                      size.width > 1080 ? "raffle.png" : "mobile-banner-v2.png"
                    }')`,
                    maskSize: "cover",
                  }}
                >
                  <source
                    src={`/assets/v3/box_loop${
                      size.width > 1080 ? "" : "_phone"
                    }.mp4`}
                    type="video/mp4"
                  />
                </video>

                {/* Overlay black opacity */}
                <div
                  className="absolute top-0 left-0 w-full h-full z-[2]"
                  style={{
                    background: `radial-gradient(circle at center, #00000000 0%,#0000007C 80%, #000000FF 100%)`,
                    maskImage: `url('/assets/v3/${
                      size.width > 1080 ? "raffle.png" : "mobile-banner-v2.png"
                    }')`,
                    maskSize: "cover",
                    opacity: 1,
                  }}
                ></div>

                {/* Music On Button */}
                <div className="flex flex-row absolute top-m right-l z-[30] space-x-s">
                  <button
                    onClick={() => setAnimationOn(!animationOn)}
                    className={`${$btnStyle} bg-white text-black `}
                  >
                    ANIMATION {animationOn ? "ON" : "OFF"}
                  </button>

                  <button
                    onClick={() => history.push("/my-bag")}
                    className={`${$btnStyle}`}
                  >
                    MY BAG
                  </button>
                </div>
              </div>

              <div className="w-[330px] 2xl:w-[504px] absolute top-[50%] xl:top-[0] left-[50%] ml-[-165px] 2xl:ml-[-252px]  flex flex-col items-center z-[10] xl:h-full justify-between xl:py-xl mt-[-125px] xl:mt-0">
                <h1 className="acpex  text-center text-[42px] xl:text-[42px] 2xl:text-[64px] leading-[44px] xl:leading-48px] 2xl:leading-[72px]">
                  <span className="text-v2primary">{game?.name}</span> Lucky
                  Draw with<span className="text-v2primary"> GeDa</span>
                </h1>

                <div className="flex flex-col items-center">
                  <div>
                    <GedaBtn txt="OPEN BOX" action={() => openBox()} />
                  </div>

                  <div className="mt-m flex flex-row items-center">
                    <p className="option-label">
                      Keys Remaining -{" "}
                      {profile && profile[game?.keyType]
                        ? profile[game?.keyType]
                        : 0}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Lucky Draw Content */}
            <div className="relative z-[15] px-[15px] md:px-[30px] mt-[30px]">
              <h2 className="page-subtitle">Box Content</h2>
              <div className="mt-[30px] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 2xl:grid-cols-6 gap-[10px]">
                {prizeContent.map((prize, i) => {
                  return <Prize prize={prize} key={i} />;
                })}
              </div>
            </div>
          </>
        )}
      </ContainerV2>

      {/* Animation */}
      <div
        className="fixed w-screen h-screen bg-black transition-all duration-300 top-0 left-0"
        style={{
          opacity: showAnimation ? 1 : 0,
          zIndex: showAnimation ? 9999 : -1,
        }}
      >
        {videoRank && ( // Check if videoRank is not null
          <video
            ref={videoRef}
            onEnded={handleVideoEnded}
            className="w-full h-full"
            playsInline
            autoPlay
          >
            <source
              src={`/assets/v3/${
                size.width > 1080 ? videoRank : `${videoRank}_phone`
              }.mp4`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        )}
      </div>

      <ModalConfirmLuckyDrawRetrieved
        isModalOpen={openLuckyDrawRetrievedModal}
        setIsModalOpen={setOpenLuckyDrawRetrievedModal}
        prize={prize}
        setPrize={setPrize}
      />
    </Auth>
  );
};

const $btnStyle = `
  cursor-pointer
  rounded-[5px] 2xl:rounded-[12px] 
  h-[29px] 2xl:h-[56px] 
  flex flex-row items-center 
  justify-center 
  text-[12px] 2xl:text-[20px] 
  leading-[14px] 2xl:leading-[24px] 
  blender-medium
  border-0
  bg-v2primary
`;
