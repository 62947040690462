import { history } from "src/stores";
import { FooterV2 } from "src/components/footer-v2";
import { GedaBtn } from "src/components/geda-btn";
import { ContainerV2 } from "src/components/container-v2";
import { useWindowSize } from "@uidotdev/usehooks";
import { useState } from "react";

export const HomeV3 = (props: any) => {
  const [musicOn, setMusicOn] = useState<boolean>(false);
  const size: any = useWindowSize();

  return (
    <ContainerV2>
      {/* Main Content */}
      <div className="mt-[20px] w-full relative">
        {/* Top Right wordings */}
        <div className="absolute w-[203px] 2xl:w-[305px] top-0 left-0 z-[50]">
          <p className="desktop text-[21px] 2xl:text-[32px] leading-[25px] 2xl:leading-[38.4px] font-[500] uppercase blender-medium">
            Onboarding billions of esports enthusiasts to web3
          </p>
          <img
            src="/assets/v3/tri.png"
            alt="tri"
            className="mt-[0px] md:mt-[40px] w-[84px] h-[84px]"
          />
        </div>
        {/* Image to center */}
        <div
          className="background-video-container h-0 relative w-full"
          style={{
            paddingTop: size.width > 1080 ? "48.14%" : "194.93%",
          }}
        >
          {/* Bg Mask */}
          <div
            className="absolute top-0 left-0 w-full h-full"
            style={{
              backgroundImage: `url('/assets/v3/${
                size.width > 1080 ? "home-banner-v2" : "mobile-banner-v2"
              }.png')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
          {/* Video */}
          <video
            autoPlay
            muted={!musicOn}
            playsInline
            loop
            className="absolute top-0 left-0 w-full h-full object-cover z-[1]"
            style={{
              maskImage: `url('/assets/v3/${
                size.width > 1080 ? "home" : "mobile"
              }-banner-v2.png')`,
              maskSize: "cover",
            }}
          >
            <source
              src={`/assets/v3/GEDA_Platform${size.width > 1080 ? "" : ""}.mp4`}
              type="video/mp4"
            />
          </video>

          {/* Overlay black opacity */}
          <div
            className="absolute top-0 left-0 w-full h-full z-[2]"
            style={{
              background: `radial-gradient(circle at center, #000000FF 0%,#0000007C 40%, #00000000 100%)`,
              maskImage: `url('/assets/v3/${
                size.width > 1080 ? "home" : "mobile"
              }-banner-v2.png')`,
              maskSize: "cover",
              opacity: 1,
            }}
          ></div>

          {/* Music On Button */}
          <button
            onClick={() => setMusicOn(!musicOn)}
            className={`${$btnStyle} bg-white text-black absolute top-m right-l z-[30]`}
          >
            MUSIC {musicOn ? "OFF" : "ON"}
          </button>

          <div className="w-[330px] 2xl:w-[504px] absolute top-[50%] left-[50%] ml-[-165px] 2xl:ml-[-252px] mt-[-66px] 2xl:mt-[-108px] flex flex-col items-center z-[10]">
            <h1 className="acpex  text-center text-[42px] xl:text-[42px] 2xl:text-[64px] leading-[44px] xl:leading-48px] 2xl:leading-[72px]">
              <span className="text-v2primary">Esports 2.0:</span> A New Era
              with <span className="text-v2primary">Web3</span>
            </h1>

            <div className="mobile">
              <GedaBtn txt="EARN NOW" action={() => history.push("/airdrop")} />
            </div>
          </div>
        </div>
        {/* Join Live Raffle Button */}
        <div
          className="absolute bottom-[-30px] 2xl:bottom-[-50px] right-0 flex flex-row items-center justify-center z-[50] cursor-pointer desktop"
          onClick={() => history.push("/airdrop")}
        >
          <div className="w-[70px] 2xl:w-[106px] h-[70px] 2xl:h-[106px] bg-[#FE5219] rounded-full flex items-center justify-center">
            <img
              src="/assets/v3/bag-01.png"
              alt="arrow"
              className="w-[26px] 2xl:w-[30px] h-[26px] 2xl:h-[30px] object-cover"
            />
          </div>
          <p className="blender-medium text-[26.6px] 2xl:text-[40px] leading-[29.3px] 2px:leading-[44px] ml-[20px] uppercase">
            Earn Now
          </p>
        </div>
        {/* Mobile Link Button */}
        <div className="absolute w-[96px] bottom-0 right-0 z-[50] mobile">
          <img
            src="/assets/v3/white-tri.png"
            alt="tri"
            className="w-[96px] h-[96px]"
            onClick={() => {
              window.open("https://twitter.com/GEDAEsports", "_target");
            }}
          />
        </div>
      </div>

      {/* Footer */}
      <div className="pt-m md:pt-[40px]">
        <FooterV2 />
      </div>
    </ContainerV2>
  );
};

const $btnStyle = `
  cursor-pointer
  w-[85px] 2xl:w-[125px] 
  rounded-[5px] 2xl:rounded-[12px] 
  h-[29px] 2xl:h-[56px] 
  flex flex-row items-center 
  justify-center 
  text-[12px] 2xl:text-[20px] 
  leading-[14px] 2xl:leading-[24px] 
  blender-medium
  border-0
`;
