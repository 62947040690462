import { useState } from "react";
import { ModalClaimForm } from "./modal-claim-form";
import moment from "moment";

export const MyBagItem = (props: any) => {
  const { item, getMyBagCb } = props;
  let { prize = {}, claimed = false, created_at = null } = item;
  const { type = "", name = "", brand, image = "" } = prize;
  prize.tagList = typeof prize?.tags === "string" ? prize?.tags.split(",") : [];

  const [openModal, setOpenModal] = useState(false);

  const handleClaim = () => {
    setOpenModal(true);
  };

  return (
    <div className="bg-[#1F232F] rounded-m overflow-hidden ">
      {/* Type */}
      <div className="w-full bg-[#252935] px-m py-s flex-row flex">
        <p className="small-content flex-1 uppercase">{type}</p>
        {created_at && (
          <p className="small-content">{moment(created_at).format("DD MMM")}</p>
        )}
      </div>
      <div className="">
        <div className="mt-s px-m">
          <p className="small-title whitespace-nowrap overflow-hidden text-ellipsis">
            {name}
          </p>
          <p className="small-content mt-xxs">{brand}</p>
        </div>
        <div className="px-m mt-s">
          <div className="w-[100%] border-[0px] border-[#2F333F] bg-[#252935] rounded-m overflow-hidden">
            <img
              src={image}
              alt="prize-img"
              className="w-full mx-auto object-fit"
            />
          </div>
        </div>

        {/* Confirm Button */}
        <div className="mt-s px-s">
          {!claimed && (
            <button
              className="bg-gradient-to-r from-[#F99433] to-[#FE5218] text-white px-m py-xs border-[0px] mx-auto justify-center flex w-full my-s"
              onClick={() => handleClaim()}
            >
              {"Claim Now"}
            </button>
          )}

          {claimed && (
            <div className="flex flex-1 justify-center items-center pb-s">
              <p className="content relative top-[-2px]">CLAIMED</p>
            </div>
          )}
        </div>
      </div>

      <ModalClaimForm
        isModalOpen={openModal}
        setIsModalOpen={setOpenModal}
        item={item}
        getMyBagCb={getMyBagCb}
      />
    </div>
  );
};
