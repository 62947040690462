import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { useState } from "react";
import { LoadingIcon } from "./loading-icon";
import { useAuthStore } from "src/stores";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
export const ModalClaimForm = (props: any) => {
  const { isModalOpen, setIsModalOpen, item, getMyBagCb } = props;

  const [contactName, setContactName] = useState<string>("");
  const [contactNumber, setContactNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { claimPrize } = useAuthStore();

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const submitForm = async () => {
    if (!contactName || !contactNumber || !email)
      return openWarningNotification("Please fill in all fields");
    if (isLoading) return;
    setIsLoading(true);
    const result = await claimPrize({
      prizeId: item?.prize?._id,
      form: {
        contactName,
        contactNumber,
        email,
      },
    });
    setIsLoading(false);
    if (!result) return;
    getMyBagCb();
    openSuccessNotification("You have submitted the form, please stay tuned!");
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={``}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      centered={true}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} />

      <div className="flex flex-col justify-start align-center min-h-[200px]">
        {/* Title */}
        <h2 className="page-subtitle">Claim your Prize</h2>
        {/* Form */}
        <div className="mt-s">
          <div>
            <p className={$labelStyle}>Your Name</p>
            <input
              type="normal"
              name="contactName"
              className={$inputClass}
              placeholder=""
              value={contactName}
              onChange={(e) => {
                setContactName(e.target.value);
              }}
            />
          </div>
          <div className="mt-[10px]">
            <p className={$labelStyle}>Contact Number</p>
            <input
              type="normal"
              name="contactNumber"
              className={$inputClass}
              placeholder="+852 0000 0000"
              value={contactNumber}
              onChange={(e) => {
                setContactNumber(e.target.value);
              }}
            />
          </div>
          <div className="mt-[10px]">
            <p className={$labelStyle}>Email</p>
            <input
              type="email"
              name="email"
              className={$inputClass}
              placeholder="abc@gmail.com"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          {/* Confirm Button */}
          <div className="mt-[24px]">
            <button
              className="bg-gradient-to-r from-[#F99433] to-[#FE5218] text-white  px-[20px] py-[10px] border-[0px] mx-auto w-full md:w-[300px] justify-center flex"
              onClick={() => submitForm()}
            >
              {isLoading ? <LoadingIcon color="#fff" size={20} /> : "Confirm"}
            </button>
          </div>
          {/* Notes */}
          <p className="mt-s text-center small-content">
            Congratulations! We will reach out to you for prize claiming in the
            coming days after you submit this form.
          </p>
        </div>
      </div>
    </Modal>
  );
};

const $labelStyle = "content";
const $inputClass =
  "mt-1 px-s py-[6px] bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#783CC7] focus:ring-[#783CC7] block w-full rounded-md text-s focus:ring-1 rounded-m";
