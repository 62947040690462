import axios from "axios";
import * as Configs from "../config";
import { errorHandling, setHeader } from "./api";
import { openSuccessNotification } from "src/components";

export class TwitterStore {
  async getTwitterAuthUrl() {
    const url = `${Configs.API_URL}/v1/twitter/auth`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async connectTwitter(params: any) {
    const url = `${Configs.API_URL}/v1/twitter/connect`;
    try {
      const result: any = await axios.post(url, params, setHeader());
      if (!result || !result.data?.success) return false;
      return result?.data?.msg;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getTwitterToken() {
    const url = `${Configs.API_URL}/v1/twitter/authorize-token`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      return result.data?.data?.access_token;
    } catch (e) {
      localStorage.removeItem("twitter_pending_task");
      return false;
    }
  }

  async doTwitterTask(token: string, action: string, name: string, task: any) {
    let version = "v1";
    let extra = {};
    switch (action) {
      case "like_tweet":
        action = "like-tweet";
        version = "v2";
        extra = {
          missionId: task?._id,
          target: task?.target,
        };
        break;
      case "follow_twitter":
        action = "follow-us";
        break;
      case "rt_tweet":
        action = "rt-tweet";
        version = "v2";
        extra = {
          missionId: task?._id,
          target: task?.target,
        };
        break;
    }

    const url = `${Configs.API_URL}/${version}/airdrop/${action}`;
    try {
      const result: any = await axios.post(
        url,
        { token, ...extra },
        setHeader()
      );
      if (!result || !result.data?.success) return false;

      openSuccessNotification("You have done the task - " + name.toUpperCase());
      return true;
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_TWITTER = "twitterStore";
