import { useCallback, useEffect, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import { history, useAirdropStore, useTwitterStore } from "src/stores";
import { LoadingIcon } from "./loading-icon";
import { openWarningNotification } from "./notification";
import { useAccount } from "wagmi";
import { ModalConfirmTweet } from "./modal-confirm-tweet";

interface FilterValues {
  [key: string]: string; // Assuming all values are strings
}

export const RaffleV3MissionList = (props: any) => {
  const {
    userId,
    handleTwitterLogin,
    getAirdropProfileCb,
    airdropProfile,
    profile,
    setUnlockReferralModal,
  } = props;
  const size: any = useWindowSize();

  const [tasks, setTasks] = useState<[] | null>();
  const [filterTasks, setFilterTasks] = useState<[] | null>();
  const [loading, setLoading] = useState<boolean>(true);
  const [filterValues, setFilterValues] = useState<FilterValues>({
    rewards: "all",
    frequency: "all",
  });
  const [tweetModal, setTweetModal] = useState<boolean>(false);
  const [twitterJwt, setTwitterJwt] = useState<string>("");
  const [twitterType, setTwitterType] = useState<string>("");
  const [twitterName, setTwitterName] = useState<string>("");
  const [selectedTask, setSelectedTask] = useState(null);

  const { address } = useAccount();
  const { getMissionList, doDailyLogin } = useAirdropStore();
  const { getTwitterToken, doTwitterTask } = useTwitterStore();

  const [, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // const isSmallDesktop = widowWidth < 1620;

  const getMissionListCb = useCallback(
    (userId) => {
      setLoading(true);
      getMissionList(userId).then((result) => {
        if (!result) return;
        setTasks(result);
        setFilterTasks(result);
        setLoading(false);
      });
    },
    [getMissionList]
  );

  const handleTwitterErr = async () => {
    openWarningNotification("You need to re-connect the twitter again");
    // jwt = await getTwitterToken();
    await handleTwitterLogin(true);
    return;
  };

  const handleTwitterTaskCb = useCallback(
    async (type: string, name: string, jwt: string, task: any) => {
      try {
        const result = await doTwitterTask(jwt, type, name, task);
        if (result === "unknown_error") {
          return handleTwitterErr();
        }
        getMissionListCb(userId);
        getAirdropProfileCb();
      } catch (e) {
        console.log("e");
        return;
      }
    },
    // eslint-disable-next-line
    [
      getTwitterToken,
      doTwitterTask,
      getMissionListCb,
      userId,
      handleTwitterLogin,
      getAirdropProfileCb,
      address,
      profile,
      handleTwitterErr,
    ]
  );

  useEffect(() => {
    getMissionListCb(userId);
  }, [userId, getMissionListCb]);

  const handleTasks = async (
    type: string,
    name: string,
    completed = false,
    task: any
  ) => {
    if (!address) {
      openWarningNotification("Please connect to Wallet");
      return;
    }

    if (!profile?.twitter_connected) {
      openWarningNotification("Please authorize Twitter Login");
      return;
    }

    switch (type) {
      case "z_predict_games":
        history.push("/prediction");
        break;
      case "like_tweet":
      case "follow_twitter":
      case "rt_tweet":
        let jwt = null;

        try {
          if (!address) {
            openWarningNotification("Please connect to Wallet");
            return;
          }

          if (!profile?.twitter_connected) {
            openWarningNotification("Please authorize Twiiter Login");
            return;
          }

          if (!profile?.twitter_jwt) {
            jwt = await getTwitterToken();
            if (!jwt) return handleTwitterErr();
          }
          if (profile?.twitter_jwt) jwt = profile?.twitter_jwt;

          if (type === "like_tweet" || type === "rt_tweet") {
            setTwitterJwt(jwt);
            setTwitterType(type);
            setTwitterName(name);
            setSelectedTask(task);
            setTweetModal(true);
          }

          if (type === "follow_twitter") {
            handleTwitterTaskCb(type, name, jwt, task);
          }
        } catch (e) {
          handleTwitterErr();
          return;
        }
        break;
      case "daily_login":
        if (completed) {
          openWarningNotification(
            "You have done this task - " + name.toUpperCase()
          );
          return;
        }

        await doDailyLogin();
        getAirdropProfileCb();
        getMissionListCb(userId);
        break;
      case "unlock_referral":
        if (airdropProfile?.referralUnlock)
          return openWarningNotification("You have already unlocked referral!");
        setUnlockReferralModal(true);
        break;
      default:
        break;
    }
  };

  const getBackgroundImage = (type: string) => {
    switch (type) {
      case "daily_login":
        return "/assets/v3/reward-tickets-v2.png";

      case "referral_program":
      case "multiplier_referral":
        return `/assets/v3/reward-referral${
          size?.width < 800 ? "-mobile" : ""
        }.png`;

      default:
        return `/assets/v3/reward-geda.png`;
    }
  };

  const getBgColor = (mission_type: string) => {
    switch (mission_type) {
      case "once":
        return "#1D1D1D";

      case "cycle":
        return "#FE791954";

      case "daily":
        return "#FFFFFF54";
    }
  };

  const renderPeriodTypeLabel = (mission_type: string, completed = false) => {
    let bgColor = "";
    let color = "#fff";
    switch (mission_type) {
      case "cycle":
        bgColor = "#1371FF";
        mission_type = "seasonal";
        break;
      case "once":
        bgColor = "#FE5219";
        break;
      case "daily":
        bgColor = "#00AF11";
        break;
    }

    if (completed) {
      mission_type = "FINISHED";
      bgColor = "#fff";
      color = "#000";
    }

    mission_type = mission_type.toUpperCase();

    return (
      <div
        className={`flex justify-center items-center h-[14px] p-xs
        md:h-[23px] z-[999] rounded-[3px] md:rounded-md`}
        style={{
          backgroundColor: bgColor,
        }}
      >
        <p
          className="small-content"
          style={{
            color: color,
          }}
        >
          {mission_type}
        </p>
      </div>
    );
  };

  useEffect(() => {
    let filteringTasks: any = tasks;

    for (const [key, value] of Object.entries(filterValues)) {
      // Check if the value is "ALL"; if so, ignore this filter condition.
      if (value.toUpperCase() === "ALL") {
        continue;
      }

      if (key.toUpperCase() === "REWARDS") {
        filteringTasks = filteringTasks?.filter((task: any) =>
          task.rewards_type.toLowerCase().includes(value.toLowerCase())
        );
      }

      if (key.toUpperCase() === "FREQUENCY") {
        filteringTasks = filteringTasks?.filter((task: any) =>
          task.mission_type.toLowerCase().includes(value.toLowerCase())
        );
      }
    }

    setFilterTasks(filteringTasks);
  }, [filterValues, tasks]); // React to changes in filterValues or tasks.

  const DropdownInput = ({ label, values }: any) => {
    return (
      <select
        // defaultValue={label}
        value={filterValues[label.toLowerCase()]}
        className="w-[140px] h-[34px] md:w-[200px] md:h-[56px] form-select bg-black mr-4 text-white border-b-2 border-gray-600 cursor-pointer"
        onChange={(e) =>
          setFilterValues({
            ...filterValues,
            [label.toLowerCase()]: e.target.value,
          })
        }
      >
        <option value={"all"} disabled className="option-label">
          {label}
        </option>
        {values.map((value: string, index: number) => (
          <option className="option-label" key={`${label}-${index}`}>
            {value.toUpperCase()}
          </option>
        ))}
      </select>
    );
  };

  return (
    <div className="flex flex-col mt-10">
      <div className="flex flex-col md:flex-row md:items-center mb-m">
        <h2 className="page-subtitle md:!mb-0">TASKS & REWARDS</h2>
        <div className="flex flex-1 md:items-center md:justify-end">
          <DropdownInput
            label="REWARDS"
            values={["all", "point", "multiplier"]}
          />
          <DropdownInput label="FREQUENCY" values={["all", "daily", "cycle"]} />
        </div>
      </div>
      <div className="flex flex-row flex-wrap">
        {loading && (
          <div className="flex w-full items-center justify-center">
            <LoadingIcon />
          </div>
        )}
        {filterTasks?.map((task, index) => {
          // Adjust the number here to limit the total number of items rendered
          const {
            _id,
            type,
            name,
            mission_type,
            rewards_type = "",
            completed,
          } = task;
          const rewards = rewards_type.split("+");

          return (
            <div
              key={_id}
              className={`flex items-center flex-row xl:flex-col w-full xl:w-[22%] 2xl:w-[380px] 2xl:h-[361px] relative mb-m xl:m-3 cursor-pointer rounded-[16px] overflow-hidden xl:max-w-[300px] 2xl:max-w-[380px]`}
              style={{
                backgroundColor: getBgColor(mission_type),
              }}
              onClick={() => handleTasks(type, name, completed, task)}
            >
              {/* background images */}
              <img
                src={getBackgroundImage(type)}
                alt="logo-bg"
                className="relative max-w-[200px] xl:max-w-full w-full h-full xl:h-[130px] 2xl:w-[379px] 2xl:h-[203px] left-0 xl:top-0 z-0 object-cover"
              />

              {/* top / left */}
              <div className="absolute left-[10px] xl:left-0 top-0 w-full 2xl:w-[379px] h-full xl:h-[130px] 2xl:h-[203px] z-[999] flex sm:flex-row mt-xs md:mt-0 xl:items-end md:p-s">
                {/* reward type label */}
                <div
                  className="flex items-center w-fit h-[16px] md:h-[23px] 
                  border-white z-[999] 
                  border-[1px] px-xs
                  rounded-sm mr-xs"
                >
                  <p className="text-[12px]">
                    {rewards_type.includes("point")
                      ? "GEDA POINTS"
                      : rewards_type.includes("ticket")
                      ? "TICKET"
                      : "MULTIPLIER"}
                  </p>
                </div>
                {renderPeriodTypeLabel(mission_type, completed)}
              </div>

              <div className="flex-0 2xl:flex-1" />

              {/* bottom / right */}
              <div className="w-full h-fit 2xl:w-[379px] 2xl:h-[160px] z-[999] p-[15px] md:p-s">
                <p
                  className="big-content"
                  style={{
                    textDecorationLine: completed ? "line-through" : "none",
                  }}
                >
                  {name}
                </p>
                {rewards.map((item, index) => {
                  const [rewardType, reward] = item.split("_");
                  return (
                    <p key={index} className="big-content !font-[700] mt-2">
                      +{reward}
                      {rewardType === "multiplier" && "% "}
                      {rewardType === "point"
                        ? " GEDA POINTS"
                        : ` ${rewardType.toUpperCase()}`}
                    </p>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      {selectedTask && (
        <ModalConfirmTweet
          isModalOpen={tweetModal}
          setIsModalOpen={setTweetModal}
          doTwitterTask={doTwitterTask}
          handleTwitterErr={handleTwitterErr}
          twitterJwt={twitterJwt}
          twitterName={twitterName}
          twitterType={twitterType}
          selectedTask={selectedTask}
          getMissionListCb={getMissionListCb}
          userId={userId}
        />
      )}
    </div>
  );
};
