import { GamePredictionRow } from "./game-prediction-row";

export const GamePrediction = (props: any) => {
  const { predictionList, address, fetchGame } = props;

  if (!predictionList) return null;

  return (
    <div className="flex flex-col flex-nowrap w-full">
      {predictionList.map((item: any, i: any) => {
        return (
          <div key={i} className="w-full pb-[30px]">
            <div className="flex flex-col md:flex-row">
              <h2 className="flex-1 page-subtitle mb-0">{item?.name}</h2>
              <div className="flex flex-row">
                <img
                  src="/assets/Tick_Green.svg"
                  alt="tick-green"
                  className="mr-[5px] w-[10px]"
                />
                <p className="mt-[2px] p-small-content">
                  Indicates Your Confirmed Selection
                </p>
              </div>
            </div>
            {item?.games.map((game: any, q: number) => {
              return (
                <div key={q} className="mt-[30px]">
                  <GamePredictionRow
                    game={game}
                    address={address}
                    fetchGame={fetchGame}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
