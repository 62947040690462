import { useWeb3Modal } from "@web3modal/react";
import { useMemo } from "react";
import { history } from "src/stores";
import { AuthStore } from "src/stores/auth";
import { useAccount, useDisconnect } from "wagmi";
import { GedaBtn } from "./geda-btn";

export const NavV2 = (props: any) => {
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const authStore = new AuthStore();

  const shortenedAddress = useMemo(() => {
    if (!address) return "";
    return address.slice(0, 6) + "..." + address.slice(-4);
  }, [address]);

  const handleLogout = () => {
    authStore.logout();
    disconnect();
  };

  return (
    <div
      className="desktop relative w-full flex flex-row z-[100] items-center justify-center"
      style={{ background: "transparent", borderColor: "transparent" }}
    >
      <div className={`${$menuStyle} !justify-start`}>
        <GedaBtn
          txt={"DISCOVER"}
          btnImg="empty-primary-btn.png"
          btnActiveImg="empty-white-btn.png"
          txtColor="#fff"
          txtActiveColor="#000"
          action={() => history.push("/")}
        />
        <GedaBtn
          txt={"TOURNAMENTS"}
          btnImg="empty-outline-btn.png"
          btnActiveImg="empty-white-btn.png"
          txtColor="#fff"
          txtActiveColor="#000"
          customStyle={`ml-xs`}
          action={() => window.open("https://geda.gg", "_self")}
        />
        <GedaBtn
          txt={"WATCH & PREDICT"}
          btnImg="empty-outline-btn.png"
          btnActiveImg="empty-white-btn.png"
          txtColor="#fff"
          txtActiveColor="#000"
          customStyle={`ml-xs`}
          action={() => history.push("/prediction")}
        />
        {/* <GedaBtn
          txt={"RAFFLES"}
          btnImg="empty-outline-btn.png"
          btnActiveImg="empty-white-btn.png"
          txtColor="#fff"
          txtActiveColor="#000"
          customStyle={`ml-xs`}
          action={() => history.push("/raffle")}
        /> */}
      </div>

      <div className={$menuStyle}>
        <img
          src={"/assets/v3/geda.png"}
          alt="icon"
          className={`w-[80px] h-[80px] 2xl:w-[100px] 2xl:h-[100px] cursor-pointer`}
          onClick={() => history.push("/")}
        />
        <p className="absolute bottom-0 blender-medium small-content uppercase">
          Beta
        </p>
      </div>

      <div className={`${$menuStyle} !justify-end`}>
        <GedaBtn
          txt={"EVENTS"}
          btnImg="empty-outline-btn.png"
          btnActiveImg="empty-white-btn.png"
          txtColor="#fff"
          txtActiveColor="#000"
          action={() => window.open("https://expo.geda.gg/", "_blank")}
        />

        <GedaBtn
          txt={"GUIDE"}
          btnImg="empty-outline-btn.png"
          btnActiveImg="empty-white-btn.png"
          txtColor="#fff"
          txtActiveColor="#000"
          customStyle={"ml-xs"}
          action={() => window.open("https://guide.geda.gg/", "_blank")}
        />

        <GedaBtn
          txt={"EARN"}
          btnImg="empty-outline-btn.png"
          btnActiveImg="empty-white-btn.png"
          txtColor="#fff"
          txtActiveColor="#000"
          customStyle={"ml-xs"}
          action={() => history.push("/airdrop")}
        />

        {!address && (
          <GedaBtn
            txt={"CONNECT WALLET"}
            btnImg="empty-white-btn.png"
            btnActiveImg="empty-primary-btn.png"
            txtColor="#000"
            txtActiveColor="#fff"
            customStyle={`ml-xs`}
            action={() => open()}
          />
        )}

        {address && (
          <>
            <GedaBtn
              txt={shortenedAddress}
              btnImg="empty-primary-btn.png"
              btnActiveImg="empty-white-btn.png"
              txtColor="#fff"
              txtActiveColor="#000"
              customStyle={`ml-xs`}
              action={() => handleLogout()}
            />
          </>
        )}
      </div>
    </div>
  );
};

const $menuStyle = "flex flex-1 flex-row items-center justify-center";
