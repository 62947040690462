export const RaffleV3Card = (props: any) => {
  const {
    label = "label",
    mainTxt = "",
    bgImg = "",
    bgColor = "#1D1D1D",
    mainImg = "",
  } = props;

  return (
    <div className="w-[205px]">
      <div
        className={`flex flex-col w-[180px] min-w-[97px] h-[220px] 
        md:w-[180px] md:min-w-[180px] md:h-[220px] 
        p-s md:p-s relative mx-auto
        overflow-hidden rounded-m`}
        style={{
          background: bgColor,
        }}
      >
        <h3 className={"card-title text-center md:mt-[15px] mb-1 md:mb-4"}>
          {label}
        </h3>
        {mainTxt !== "" && (
          <p className="card-big-content text-center z-10">{mainTxt}</p>
        )}

        {mainImg !== "" && (
          <img
            className="w-[232px] object-cover"
            src={mainImg}
            alt="main_img"
          />
        )}
        {bgImg && (
          <img
            src={bgImg}
            alt="logo-bg"
            className="object-contain h-[136px] absolute z-0 bottom-[6px] md:bottom-[15px] mx-auto inset-x-0"
          />
        )}
      </div>
    </div>
  );
};
