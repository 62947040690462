import { Router, Switch, Route, Redirect } from "react-router";
import { history } from "./stores";

// Components
import { LuckyDrawGame } from "./pages/lucky-draw-game";
import { MyBag } from "./pages/my-bag";
import { RaffleV2 } from "./pages/raffle-v2";
import { HomeV3 } from "./pages/index-v3";
import { Airdrop } from "./pages/airdrop";
import { PredictionV3 } from "./pages/prediction-v3";
import { PredictionV3Detail } from "./pages/prediction-v3-detail";
export default function Routers() {
  return (
    <Router history={history}>
      <Switch>
        <Route path={"/"} exact component={HomeV3} />
        {/* Lucky Draw */}
        <Route path={"/raffle-beta-test"} exact component={RaffleV2} />
        <Route path={"/raffle/:id"} exact component={LuckyDrawGame} />
        {/* Prediction */}
        <Route path={"/prediction"} exact component={PredictionV3} />
        <Route path={"/prediction/:id"} exact component={PredictionV3Detail} />
        {/* My Bag */}
        <Route path={"/my-bag"} exact component={MyBag} />
        {/* Airdrop */}
        <Route path={"/airdrop"} exact component={Airdrop} />

        <Route path="*">
          <Redirect from={"/"} to={"/"} />
        </Route>
      </Switch>
    </Router>
  );
}
