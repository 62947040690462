export const RAFFLES_LIST = [
  {
    name: "GeDa Beta Test",
    desc: "KuKu NFT, ROG Ally, ROG Phone 6",
    image: "/assets/v2/Game_02.jpg",
    isSelectable: true,
    isEnded: true,
    type: ["web3"],
    gameId: "geda-beta-test",
    logo: "/assets/v2/Game_02.jpg",
    avatar: "/assets/v2/Game_02.jpg",
    keyType: "geda_key",
  },
];
